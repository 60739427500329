import Rectangle1 from "../../../assets/images/gallery/Rectangle-1.png";
import Rectangle2 from "../../../assets/images/gallery/Rectangle-2.png";
import Rectangle3 from "../../../assets/images/gallery/Rectangle-3.png";
import Rectangle4 from "../../../assets/images/gallery/Rectangle-4.png";
import Rectangle5 from "../../../assets/images/gallery/Rectangle-5.png";
import { Container, Row, Col } from "reactstrap";
export default function BestTraveling() {
  return (
    <section className="best-traveling-section">
      <Container>
        <p className="sec-title-heading">Gallery of our trip</p>
        <h2 className="sec-title">Best Traveling</h2>
        <Row form>
          <Col lg={4}>
            <img src={Rectangle1} className="img-fluid" alt="" />
          </Col>
          <Col lg={8}>
            <img src={Rectangle2} className="img-fluid" alt="" />
          </Col>
          <Col lf={4}>
            <img src={Rectangle3} className="img-fluid" alt="" />
          </Col>
          <Col lf={4}>
            <img src={Rectangle4} className="img-fluid" alt="" />
          </Col>
          <Col lf={4}>
            <img src={Rectangle5} className="img-fluid" alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
