import { useRef } from "react";
import { Container } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import slideData from "./slideData";
import SlideCard from "../../../components/SlideCard";

const options = {
  nav: true,
  loop: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    767: {
      items: 3,
    },
    991: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    // 1600: {
    //   items: 5,
    // },
  },
};

const events = {
  onDragged: function (event) {},
  onChanged: function (event) {},
};

export default function BestDestination() {
  const refferencr = useRef(null);
  return (
    <section className="BestDestination-section">
      <Container>
        <p className="sec-title-heading">Recommended place</p>
        <h2 className="sec-title">Best Destination</h2>
      </Container>
      <div className="book-slider-wrapper">
        <div className="book-slider">
          <OwlCarousel ref={refferencr} options={options} events={events}>
            {slideData.map((data, index) => (
              <div key={index} className="book-SlideCard-wrapper">
                <SlideCard {...data} />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}
