import { Button } from "reactstrap";
import { LineArrow } from "../SvgComponents";

export default function SlideCard(props) {
  const {
    title = "",
    dec = "",
    image = "",
    //  price = 0
  } = props;

  return (
    <div className="book-SlideCard">
      <div className="wrapper">
        <img className="render-image" src={image} alt={title} />
        <span className="dark-layer" />
        <div className="content">
          <div>
            <h3 className="title">{title}</h3>
            <p className="dec">{dec}</p>
          </div>
          <div className="buy">
            <span />
            {/* <p className="price">{price}$</p> */}
            <Button color="primary" className="arrow">
              <span>
                See Packages
                <LineArrow />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
