import { Link } from "react-router-dom";
export default function LinkPage({ linkPage = [] }) {
  return (
    <div className="link-page">
      {linkPage.map(({ type = "text", name = "", link = "/" }) => (
        <div className="item">
          {type === "text" && <span>{name}</span>}
          {type === "link" && <Link to={link}>{name}</Link>}
        </div>
      ))}
    </div>
  );
}
