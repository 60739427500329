import { Input, Button } from "reactstrap";
import mapIcon from "../../assets/images/icons/map-pin.svg";
import { LineArrow } from "../SvgComponents";

export default function SearchDestination() {
  return (
    <div className="search-destination">
      <div className="wrapper">
        <div className="imputs">
          <div className="des">
            <img src={mapIcon} alt="" />
            <Input placeholder="Find destination" />
          </div>
          <div>
            <Input placeholder="Keyword (optional)" />
          </div>
        </div>
        <div>
          <Button color="primary" className="arrow">
            <span>
              <LineArrow />
              Search
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
