import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import logo from "../../../assets/images/logo.png";
import cx from "classnames";
import Menus from "./menus";

function MenuIcon() {
  return (
    <svg
      fill="#fff"
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="menu"
      width="1em"
      height="1em"
      ariaHidden="true"
    >
      <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
    </svg>
  );
}

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [menu, setMenu] = useState(false);
  const handleScroll = () => {
    setIsSticky(() => (window.scrollY > 70 ? true : false));
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <header id="header" className={cx({ isSticky })}>
      <Container fluid="xl">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menus active={menu} close={() => setMenu(false)} />
        <div className="links">
          <Link to="/register">Register</Link>
          <Link to="/login" className="btn btn-primary">
            Sign in
          </Link>
          <button onClick={() => setMenu(true)} className="menu-btn d-lg-none">
            <MenuIcon />
          </button>
        </div>
      </Container>
    </header>
  );
}
export default Header;
