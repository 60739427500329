import { Link } from "react-router-dom";
import aboutImage from "../../../assets/images/backgrounds/about.png";
import { Container } from "reactstrap";

export default function AboutUs() {
  return (
    <section className="about-us-sec" style={{ backgroundImage: `url(${aboutImage})` }}>
      <Container>
        <h2 className="sec-title">About US</h2>
        <p>
          Experience beautiful wide open spaces. Journey along epic trails, hike to and discover the
          stunning landscapes
        </p>
        <Link to="/" className="btn btn-primary">
          Discover more
        </Link>
      </Container>
    </section>
  );
}
