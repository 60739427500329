import { Link } from "react-router-dom";
import cx from "classnames";

export default function Menus({ active = false, close = () => {} }) {
  const menuData = [
    { name: "Home", link: "/" },
    { name: "Destination", link: "/" },
    { name: "About Us", link: "/" },
    { name: "Gallery", link: "/" },
    { name: "Contact", link: "/" },
  ];

  return (
    <>
      <span onClick={close} className={cx("menu-bg d-lg-none", { active })} />
      <div className={cx("menus", { active })}>
        <ul>
          {menuData.map(({ name, link }) => (
            <li>
              <Link to={link} onClick={close}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
