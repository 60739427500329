import logo from "../../../../assets/images/logo.png";
import { Input, Button } from "reactstrap";

export default function Info() {
  return (
    <div className="info">
      <img src={logo} className="logo" alt="logo" />
      <p className="info-text">
        Source: Sharjah Update | Published:
        <br />
        October 12, 2020 - 3:32 pm
      </p>
      <p className="lable-text">
        Receive weekly updates on Sharjah business news direct to your inbox
      </p>
      <div className="subsctibe">
        <div className="flex-grow-1">
          <Input placeholder="Your e-mail" />
        </div>
        <Button color="primary">Subscribe to</Button>
      </div>
    </div>
  );
}
