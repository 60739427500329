import PackageBanner from "../../components/banners/PackageBanner";
import PackageCard from "./PackageCard";

export default function Package() {
  return (
    <>
      <PackageBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Adventure Package - Exclusive" },
        ]}
      />
      <PackageCard />
    </>
  );
}
