import ushna from "../../../assets/images/logos/ushna.png";
import { Row, Col } from "reactstrap";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { LineArrow } from "../../../components/SvgComponents";
import cx from "classnames";

function RenderCard(props) {
  const { title, name, decription, validTill, logo } = props;
  return (
    <div className="render-card">
      <div className="logo-wrapper">
        <img src={logo} alt={name} />
      </div>
      <div className="content">
        <div>
          <p className="name">{name}</p>
          <p className="title">{title}</p>
          <p className="decription">{decription}</p>
        </div>
        <p className="validTill">
          <b>Expires:</b> <i>{validTill}</i>
        </p>
      </div>
    </div>
  );
}

export default function DetailView() {
  const data = {
    logo: ushna,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  };
  const btns = [{ name: "About", active: "true" }, { name: "Terms" }];
  return (
    <>
      <section className="voucherDetailView">
        <div className="voucherDetailView-card">
          <Row className="align-items-center  ">
            <Col sm={6}>
              <div className="tab-package-btn w-100">
                <div className="wrapper">
                  {btns.map(({ name, active = false }) => (
                    <button className={cx({ active: active })}>{name}</button>
                  ))}
                </div>
              </div>
              <RenderCard {...data} />
            </Col>
            <Col sm={6}>
              <div className="d-table mx-auto">
                <QRCode value="discover-shurooq" />
                <p className="text-center">
                  <b>Scan QR Code</b>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="voucherDetailView">
        <div className="voucherDetailView-card">
          <Row className="align-items-center  ">
            <Col sm={6}>
              <RenderCard {...data} />
            </Col>
            <Col sm={6}>
              <div className="d-table mx-auto">
                <Link style={{ width: 220 }} className="btn btn-primary arrow" to="/login">
                  <span>
                    <LineArrow />
                    Login to Get QR code
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
