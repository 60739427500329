import HomeBanner from "../../components/banners/HomeBanner";
import BestDestination from "./BestDestination";
import OffersYouMayLike from "./OffersYouMayLike";
import AboutUs from "./AboutUs";
import BestTraveling from "./BestTraveling";
import { Link } from "react-router-dom";
import specialGift from "../../assets/images/special-gift.png";
import { scrollToTopSmooth } from "../../utils";
function Home() {
  return (
    <>
      <HomeBanner />
      <BestDestination />
      <section className="text-center">
        <Link to="/upload" onClick={scrollToTopSmooth}>
          <img src={specialGift} style={{ maxWidth: 900 }} className="w-100" alt="" />
        </Link>
      </section>
      <OffersYouMayLike />
      <AboutUs />
      <BestTraveling />
    </>
  );
}

export default Home;
