import { Link } from "react-router-dom";

function Arrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
      <path
        d="M4.16663 1.66665L7.49996 4.99998L4.16663 8.33331"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default function Menus() {
  const menuData = [
    {
      name: "Spend & Win",
      link: "/",
    },
    {
      name: "Destinations",
      link: "/",
    },
    {
      name: "Offers",
      link: "/",
    },
    {
      name: "About Us",
      link: "/",
    },
    {
      name: "Gallery",
      link: "/",
    },
    {
      name: "Contact",
      link: "/",
    },
  ];
  return (
    <div className="f-menus">
      <h3>Menu</h3>
      <div>
        {menuData.map(({ name, link }) => (
          <div className="menu-item">
            <Link to={link}>
              {name}
              <Arrow />
            </Link>
          </div>
        ))}
      </div>
      <div>
        <div className="b-links">
          <Link to="/">Terms</Link>
          <Link to="/">Privacy</Link>
        </div>
        <p className="copy-text">
          Sharjah Investment and Development Authority (Shurooq) ©2021
          <br />
          All rights reserved. Government of Sharjah
        </p>
      </div>
    </div>
  );
}
