import Layout from "../components/layout";
import {
  Home,
  BookYourExperience,
  Package,
  Register,
  Login,
  VoucherDetails,
  Upload,
} from "../pages";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

function Routes() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/book-your-experience" component={BookYourExperience} />
          <Route exact path="/package" component={Package} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/voucher-details" component={VoucherDetails} />
          <Route exact path="/upload" component={Upload} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default Routes;
