export const scrollToTop = () => window.scrollTo({ top: 0 });
export const scrollToTopSmooth = () => window.scrollTo({ behavior: "smooth", top: 0 });

export const scrollToSection = (sectionId) => {
  const id = document.getElementById(sectionId);
  const header = document.querySelector("header");
  window.scrollTo({
    behavior: "smooth",
    top: id.offsetTop - header.offsetHeight,
    left: 0,
  });
};
