import jetski from "../../../assets/images/icons/banner/jetski.svg";
import passport from "../../../assets/images/icons/banner/passport.svg";
import compass from "../../../assets/images/icons/banner/compass.svg";
import knife from "../../../assets/images/icons/banner/knife.svg";
import thermo from "../../../assets/images/icons/banner/thermo.svg";
import forest from "../../../assets/images/icons/banner/forest.svg";
import beach from "../../../assets/images/icons/banner/beach.svg";
import caravan from "../../../assets/images/icons/banner/caravan.svg";
import car from "../../../assets/images/icons/banner/car.svg";
import kayak from "../../../assets/images/icons/banner/kayak.png";
import hiking from "../../../assets/images/icons/banner/hiking.png";
import book from "../../../assets/images/icons/banner/book.png";
import towTruck from "../../../assets/images/icons/banner/tow-truck.png";
import jeepUp from "../../../assets/images/icons/banner/jeepUp.png";
import jeep from "../../../assets/images/icons/banner/jeep.png";

const bottomContentData = [
  { icon: jeep, title: "Adventure" },
  { icon: kayak, title: "Kayaking" },
  { icon: hiking, title: "Trekking" },
  { icon: book, title: "Education" },
  { icon: jeepUp, title: "Off Road" },
  { icon: forest, title: "Cycling" },
  { icon: beach, title: "Family fun" },
  { icon: caravan, title: "Coastal escapes" },
];

export default bottomContentData;
