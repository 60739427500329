import { Container, Row, Col } from "reactstrap";
import bookData from "./bookData";
// import starIcon from "../../assets/images/icons/star.svg";
import mapIcon from "../../assets/images/icons/maps-and-flags.png";
import HeaderBanner from "../../components/banners/HeaderBanner";
import cx from "classnames";
const tempImage =
  "https://images.unsplash.com/photo-1624561500881-d97c12c521e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80";

function RenderBookCard(props) {
  const {
    title,
    price,
    //  duration, age,
    rating,
    image,
  } = props;

  const top = () => (
    <div className="top-content">
      {/* <div>
        <div>
          <p className="duration">
            <span>Duration:</span> {duration}
          </p>
          <p>
            <span>Age:</span> {age}
          </p>
        </div>
      </div> */}
      <div>
        <p className="rating">
          <img src={mapIcon} alt="rating" />
          {rating}
        </p>
      </div>
    </div>
  );

  const bottom = () => (
    <div className="bottom-content">
      <h3 className="title pr-4">{title}</h3>
      <p className="price">AED {price} Only</p>
    </div>
  );
  return (
    <div className="book-exp-card" style={{ backgroundImage: `url(${image})` }}>
      <div className="content">
        {top()}
        {bottom()}
      </div>
    </div>
  );
}

export default function BookYourExperience() {
  const btns = [{ active: "true" }, {}, {}, {}];

  return (
    <>
      <HeaderBanner
        linkPage={[{ type: "link", name: "Home", link: "/" }, { name: "Book Your Experience" }]}
      />
      <section className="book-youre-experience-section">
        <Container>
          <h2 className="sec-title">Book Your Experience</h2>
          <div className="tab-box-btn">
            <div className="wrapper">
              {btns.map(({ active = false }) => (
                <button className={cx({ active: active })}>Tag text</button>
              ))}
            </div>
          </div>
          <Row>
            {bookData.map((data, index) => (
              <Col md={6} key={"bookData" + index} className="book-exp-card-col">
                <RenderBookCard {...data} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
