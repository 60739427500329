import { Container } from "reactstrap";
import bannerImage from "../../../assets/images/backgrounds/h-banner.png";
import bottomContentData from "./bottomContentData";
import SearchDestination from "../../SearchDestination";
import CaretRight from "../../../assets/images/icons/CaretRight.svg";

function BottomContent() {
  return (
    <div className="b-bottom-content">
      {bottomContentData.map(({ icon, title }) => (
        <div className="content">
          <div className="item">
            <img src={icon} className="icon" alt={title} />
            <p className="b-title">{title}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function HomeBanner() {
  return (
    <section className="h-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
      <Container>
        <span className="scrollText">
          scroll down
          <img src={CaretRight} alt="" />
        </span>
        <div />
        <div>
          <h1>Find best destination for your trip</h1>
          <p className="main-dec">
            It's easy to customize your travel experience with our robust flight and hotel search
            filter options and Shurooq travel tools like flight fare alerts, deal - finder calendar,
            and early bird deals.
          </p>
          <SearchDestination />
        </div>
        <BottomContent />
      </Container>
    </section>
  );
}
