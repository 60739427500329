import { Container, Row, Col } from "reactstrap";
import offerData from "./offerData";

function RenderCard(props) {
  const { title, name, decription, validTill, logo } = props;
  return (
    <div className="offer-card">
      <div className="logo-wrapper">
        <img src={logo} alt={name} />
      </div>
      <div className="content">
        <div>
          <p className="name">{name}</p>
          <p className="title">{title}</p>
          <p className="decription">{decription}</p>
        </div>
        <p className="validTill">Expires {validTill}</p>
      </div>
    </div>
  );
}

export default function OffersYouMayLike() {
  return (
    <section className="OffersYouMayLike-section">
      <Container>
        <h2 className="sec-title">Offers you may like</h2>
        <Row>
          {offerData.map((data, index) => (
            <Col className="offer-card-col" sm={6} lg={4} key={"offerData" + index}>
              <RenderCard {...data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
