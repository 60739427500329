export const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M20 22H18V20C18 19.2044 17.6839 18.4413 17.1213 17.8787C16.5587 17.3161 15.7956 17 15 17H9C8.20435 17 7.44129 17.3161 6.87868 17.8787C6.31607 18.4413 6 19.2044 6 20V22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13ZM12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z" />
  </svg>
);

export const UserHappyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M17.9996 17V22H15.9996V17C15.9996 12.549 18.6436 8.715 22.4466 6.984L23.2746 8.804C21.7013 9.51958 20.3673 10.6729 19.4319 12.1263C18.4965 13.5797 17.9993 15.2716 17.9996 17ZM7.99961 17V22H5.99961V17C5.99976 15.2718 5.50244 13.5801 4.56705 12.1269C3.63166 10.6737 2.29774 9.52051 0.724609 8.805L1.55261 6.984C3.4753 7.85849 5.10561 9.26796 6.24883 11.0441C7.39205 12.8201 7.99984 14.8878 7.99961 17ZM11.9996 12C10.6735 12 9.40176 11.4732 8.46408 10.5355C7.52639 9.59785 6.99961 8.32608 6.99961 7C6.99961 5.67392 7.52639 4.40215 8.46408 3.46447C9.40176 2.52678 10.6735 2 11.9996 2C13.3257 2 14.5975 2.52678 15.5351 3.46447C16.4728 4.40215 16.9996 5.67392 16.9996 7C16.9996 8.32608 16.4728 9.59785 15.5351 10.5355C14.5975 11.4732 13.3257 12 11.9996 12ZM11.9996 10C12.3936 10 12.7837 9.9224 13.1477 9.77164C13.5116 9.62087 13.8424 9.3999 14.1209 9.12132C14.3995 8.84274 14.6205 8.51203 14.7712 8.14805C14.922 7.78407 14.9996 7.39397 14.9996 7C14.9996 6.60603 14.922 6.21593 14.7712 5.85195C14.6205 5.48797 14.3995 5.15726 14.1209 4.87868C13.8424 4.6001 13.5116 4.37913 13.1477 4.22836C12.7837 4.0776 12.3936 4 11.9996 4C11.204 4 10.4409 4.31607 9.87829 4.87868C9.31568 5.44129 8.99961 6.20435 8.99961 7C8.99961 7.79565 9.31568 8.55871 9.87829 9.12132C10.4409 9.68393 11.204 10 11.9996 10Z" />
  </svg>
);

export const DurationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM15.536 7.05L16.95 8.464L12 13.414L10.586 12L15.536 7.05Z" />
  </svg>
);

export const CarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522 22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V13.5L0.757 13.19C0.540753 13.1358 0.348809 13.011 0.211655 12.8352C0.0745022 12.6595 5.86043e-06 12.4429 0 12.22L0 11.5C0 11.3674 0.0526784 11.2402 0.146447 11.1464C0.240215 11.0527 0.367392 11 0.5 11H2L4.48 5.212C4.63432 4.852 4.89096 4.54524 5.21805 4.32978C5.54515 4.11432 5.92832 3.99965 6.32 4H17.68C18.0713 4.00004 18.4541 4.1149 18.7808 4.33033C19.1075 4.54577 19.3638 4.85231 19.518 5.212L22 11H23.5C23.6326 11 23.7598 11.0527 23.8536 11.1464C23.9473 11.2402 24 11.3674 24 11.5V12.22C24 12.4429 23.9255 12.6595 23.7883 12.8352C23.6512 13.011 23.4592 13.1358 23.243 13.19L22 13.5V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM20 18V13H4V18H20ZM5.477 11H18.523C18.6863 10.9999 18.8471 10.9598 18.9914 10.8832C19.1357 10.8066 19.2589 10.6958 19.3505 10.5605C19.442 10.4253 19.4991 10.2697 19.5166 10.1073C19.5341 9.94488 19.5116 9.78067 19.451 9.629L18 6H6L4.549 9.629C4.4884 9.78067 4.46588 9.94488 4.4834 10.1073C4.50092 10.2697 4.55795 10.4253 4.64951 10.5605C4.74106 10.6958 4.86435 10.8066 5.0086 10.8832C5.15285 10.9598 5.31367 10.9999 5.477 11ZM5 14C7.317 14 8.879 14.755 9.686 16.264C9.72679 16.3402 9.74714 16.4257 9.74506 16.5121C9.74297 16.5985 9.71852 16.6829 9.6741 16.757C9.62968 16.8311 9.5668 16.8925 9.49159 16.9351C9.41639 16.9777 9.33143 17 9.245 17H6C5.73478 17 5.48043 16.8946 5.29289 16.7071C5.10536 16.5196 5 16.2652 5 16V14ZM19 14V16C19 16.2652 18.8946 16.5196 18.7071 16.7071C18.5196 16.8946 18.2652 17 18 17H14.755C14.6687 16.9999 14.5838 16.9774 14.5088 16.9347C14.4337 16.892 14.3709 16.8307 14.3266 16.7566C14.2823 16.6825 14.258 16.5981 14.2559 16.5118C14.2539 16.4255 14.2742 16.3401 14.315 16.264C15.12 14.754 16.682 14 19 14Z" />
  </svg>
);

export const CalanderIocn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11ZM6 14H8V16H6V14ZM10 14H18V16H10V14Z" />
  </svg>
);

export const SubIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M11 11H12H13H19V13H13H12H11H5V11H11Z" />
  </svg>
);

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" />
  </svg>
);

export const LineArrow = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36.1 25.8"
    enableBackground="new 0 0 36.1 25.8"
    xmlSpace="preserve"
  >
    <g>
      <line
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="3"
        strokeMiterlimit="10"
        x1="0"
        y1="12.9"
        x2="34"
        y2="12.9"
      ></line>
      <polyline
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="3"
        stroke-Miterlimit="10"
        points="22.2,1.1 34,12.9 22.2,24.7   "
      ></polyline>
    </g>
  </svg>
);
