import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input } from "reactstrap";
import cx from "classnames";

function UploadContent() {
  const btns = [{ name: "About", active: "true" }, { name: "Terms" }, { name: "Faq" }];
  return (
    <div className="UploadContent">
      {/* <h2>Terms</h2> */}
      <div className="tab-package-btn w-100">
        <div className="wrapper">
          {btns.map(({ name, active = false }) => (
            <button className={cx({ active: active })}>{name}</button>
          ))}
        </div>
      </div>
      <p>
        Get ready for an exciting off-road experience as you discover the most spectacular vistas
        Mleiha has to offer. Your journey will last approximately 6 hours as you take in views from
        two different peaks, visit ancient sites, stop for picture-perfect photos of the stunning
        landscape and conclude your adventure in the relaxing setting of our Sunset Lounge.
      </p>
    </div>
  );
}
function UploadFile() {
  return (
    <div className="UploadFile">
      <lable className="upload-select">
        <Input type="file" />
        <span>
          Select Images to Upload Your Invoices - <span className="unli">Browse</span>
        </span>
      </lable>
    </div>
  );
}

function UploadCard() {
  return (
    <div className="UploadCard">
      <Row>
        <Col md={6}>
          <UploadContent />
        </Col>
        <Col md={6}>
          <UploadFile />
        </Col>
      </Row>
    </div>
  );
}

export default function Upload() {
  return (
    <div>
      <UploadBanner
        linkPage={[{ type: "link", name: "Home", link: "/" }, { name: "Upload File" }]}
      />
      <section className="uploadSection">
        <UploadCard />
      </section>
    </div>
  );
}
