import img1 from "../../assets/images/book/1.png";
import img2 from "../../assets/images/book/2.png";
import img3 from "../../assets/images/book/3.png";
import img4 from "../../assets/images/book/4.png";
import img5 from "../../assets/images/book/5.png";
import img6 from "../../assets/images/book/6.png";

const bookData = [
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img1,
  },
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img2,
  },
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img3,
  },
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img4,
  },
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img5,
  },
  {
    title: "Fossil Fun Expedition Standard",
    price: 35,
    duration: "1.5-2 hours",
    age: "5+",
    rating: "5.0",
    image: img6,
  },
];

export default bookData;
