import headBanner from "../../../assets/images/backgrounds/package.png";
import LinkPage from "../../LinkPage";
import OwlCarousel from "react-owl-carousel2";
import { useRef } from "react";

const slideData = [
  { image: headBanner },
  { image: headBanner },
  { image: headBanner },
  { image: headBanner },
  { image: headBanner },
];

const options = {
  nav: false,
  loop: true,
  autoplay: true,
  items: 1,
};
const events = {
  onDragged: function (event) {},
  onChanged: function (event) {},
};

export default function PackageBanner(props) {
  const refferencr = useRef(null);
  const { linkPage = [] } = props;
  return (
    <section className="package-banner">
      <div className="content">
        <div className="slider-wrapper">
          <OwlCarousel ref={refferencr} options={options} events={events}>
            {slideData.map(({ image }, index) => (
              <div key={index}>
                <div className="render-iamge" style={{ backgroundImage: `url(${image})` }}></div>
              </div>
            ))}
          </OwlCarousel>
        </div>
        <div className="b-content">
          <div>
            <p className="name-text">Discover Mleiha</p>
          </div>
        </div>
        <LinkPage linkPage={linkPage} />
      </div>
    </section>
  );
}
