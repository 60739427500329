import headBanner from "../../../assets/images/backgrounds/head-banner.png";
import LinkPage from "../../LinkPage";

export default function HeaderBanner(props) {
  const { backgroundImage = headBanner, linkPage = [] } = props;
  return (
    <section className="head-banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content">
        <LinkPage linkPage={linkPage} />
      </div>
    </section>
  );
}
